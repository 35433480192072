define("@ember/-internals/runtime/lib/system/object", ["exports", "@ember/-internals/container", "@ember/-internals/utils", "@ember/-internals/metal", "@ember/-internals/runtime/lib/system/core_object", "@ember/-internals/runtime/lib/mixins/observable", "@ember/debug"], function (_exports, _container, _utils, _metal, _core_object, _observable, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FrameworkObject = void 0;
  /**
  @module @ember/object
  */
  var _a;
  class EmberObject extends _core_object.default.extend(_observable.default) {
    get _debugContainerKey() {
      var factory = (0, _container.getFactoryFor)(this);
      return factory !== undefined && factory.fullName;
    }
  }
  var _default = EmberObject;
  _exports.default = _default;
  var FrameworkObject = class FrameworkObject extends EmberObject {};
  _exports.FrameworkObject = FrameworkObject;
  if (false /* DEBUG */) {
    var INIT_WAS_CALLED = Symbol('INIT_WAS_CALLED');
    var ASSERT_INIT_WAS_CALLED = (0, _utils.symbol)('ASSERT_INIT_WAS_CALLED');
    _exports.FrameworkObject = FrameworkObject = class DebugFrameworkObject extends EmberObject {
      constructor() {
        super(...arguments);
        this[_a] = false;
      }
      init(properties) {
        super.init(properties);
        this[INIT_WAS_CALLED] = true;
      }
      [(_a = INIT_WAS_CALLED, ASSERT_INIT_WAS_CALLED)]() {
        (false && !(this[INIT_WAS_CALLED]) && (0, _debug.assert)("You must call `super.init(...arguments);` or `this._super(...arguments)` when overriding `init` on a framework object. Please update " + this + " to call `super.init(...arguments);` from `init` when using native classes or `this._super(...arguments)` when using `EmberObject.extend()`.", this[INIT_WAS_CALLED]));
      }
    };
    (0, _metal.addListener)(FrameworkObject.prototype, 'init', null, ASSERT_INIT_WAS_CALLED);
  }
});